<template>
  <v-card elevation="4" class="mb-8">
    <v-card-title class="pb-6 d-flex flex-row justify-space-between">
      <v-skeleton-loader width="100%" type="heading" />
    </v-card-title>

    <v-card-text class="d-flex justify-space-between">
      <div v-for="item in rows" :key="item" class="d-flex">
        <v-skeleton-loader type="avatar" />
        <div class="d-flex flex-column ml-2">
          <v-skeleton-loader type="text" width="120px" class="mb-1"/>
          <v-skeleton-loader type="text" width="80px" />
          <v-skeleton-loader type="text" width="100px" />
        </div>
      </div>
    </v-card-text>

    <v-divider class="mt-6" />

    <v-card-text>
      <div class="summary-data">
        <div v-for="item in 6" :key="item">
          <v-skeleton-loader class="mb-3" type="text" width="120px" />
          <div class="summary-data__data mb-2">
            <v-skeleton-loader type="text" width="150px" />
            <v-skeleton-loader type="text" width="100px" />
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['rows']
}
</script>

<style lang="scss" scoped>
.summary-data {
  display: flex;
  flex-direction: column;
  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  &__name {
  }
  &__data {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>